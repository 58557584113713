import React, { useState } from 'react';
// import logo from '../../images/logo.svg';
import logo from '../../images/PartyLogo9.svg';
import hero_half from '../../images/gallery2.gif';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import rosette from '../../images/rosette.svg';
import cornerStars from '../../images/corner-stars.svg';
import dangleStars from '../../images/dangle-stars.svg';
import cornerStarsInd2 from '../../images/corner-stars-ind-2.svg';
import tophat from '../../images/tophat.svg';
import launchIcon from '../../images/launch-icon.svg';
import twitterIcon from '../../images/twitter-icon.svg';
import discordIcon from '../../images/discord-icon.svg';
import bscLogo from '../../images/bsc-logo.png';

const Hero: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div id="home" className="flex flex-col items-center pt-20 md:pt-32">
        <div className="flex flex-col-reverse md:flex-row items-center w-5/6 md:w-4/6 gap-8">
          <div className="w-full md:w-2/3">
            <h1>
              <img src={logo} alt={t('Meta.title', 'Monsta.Party')} />
            </h1>
            <div className="relative">
              <div
                className={
                  'absolute -top-6 lg:-top-8 xl:-top-12 right-4 flex gap-2 text-xs items-center justify-center text-yellow-300'
                }
              >
                <div>Only on BNB Chain</div>
                <div>
                  <img src={bscLogo} alt={'BNB Chain'} width={24} height={24} />
                </div>
              </div>
            </div>
            <p className="mb-4 text-lg">
              {t(
                'Hero.intro.part1',
                'Monsta Party is a collection of 10,000 Play-2-Earn NFTs on BNB Chain, and is the first NFT project backed by a legit DeFi protocol, Cake Monster.',
              )}
            </p>
            <p className="mb-4 text-lg">
              {t(
                'Hero.intro.part2',
                "Party Monstas like gettin’ down ’n’ dirty, dressin’ to tha’ nines, and bustin’ a groove... So make sure you feed 'em little and often to keep their party energy up and keep the Monsta Party bumpin’!",
              )}
            </p>
          </div>

          <div className="w-5/6 md:w-1/3 flex flex-col">
            <div className={`relative hover:animate-bounceOnce`}>
              <img className="absolute rosette w-36" src={tophat} alt="" />
              <img className="absolute corner-stars w-20" src={cornerStarsInd2} alt="" />
              <img
                className="hidden md:inline absolute dangle-stars w-20"
                src={dangleStars}
                alt=""
              />
              <img
                className="rounded-xl border-4 border-white"
                src={hero_half}
                alt="Monsta Party"
              />
            </div>
          </div>
        </div>
        <div className="w-5/6 md:w-4/6 flex flex-col md:flex-row items-center mt-6">
          <a
            className="w-56 h-16 md:h-12 lg:h-16 sm:mx-4 mb-2 launch-button cursor-pointer font-super flex items-center justify-center"
            title="App"
            href="https://app.monsta.party"
            target="_blank"
          >
            <img src={launchIcon} title={'Discord'} className="mr-2 w-8 md:w-6 lg:w-8" />
            <div className="text-xl md:text-sm lg:text-xl">App</div>
          </a>
          <a
            className="w-56 h-16 md:h-12 lg:h-16 sm:mx-4 mb-2 twitter-button cursor-pointer font-super flex items-center justify-center"
            title="Twitter"
            href="https://twitter.com/MonstaPartyNFTs"
            target="_blank"
          >
            <img src={twitterIcon} title={'Twitter'} className="mr-2 w-6 md:w-5 lg:w-6" />
            <div className="text-xl md:text-sm lg:text-xl">Twitter</div>
          </a>
          <a
            className="w-56 h-16 md:h-12 lg:h-16 sm:mx-4 mb-2 discord-button cursor-pointer font-super flex items-center justify-center"
            title="Discord"
            href="https://discord.com/invite/MonstaPartyNFTs"
            target="_blank"
          >
            <img src={discordIcon} title={'Discord'} className="mr-2 w-8 md:w-6 lg:w-8" />
            <div className="text-xl md:text-sm lg:text-xl">Discord</div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Hero;
